/**
  Video thumbnails and large player with overlay
 */
.page-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 2000;
  opacity: 0;
  background: #000;
  transition: all 0.3s;
  &.show {
    opacity: 0.7;
    visibility: visible;
  }
}

.fullscreen-centered {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2500;

  .iframe-wrapper {
    position: relative;
    padding-top: 25px;
    height: 90%;
    width: 90%;
    &.video {
      padding-bottom: 49.3%; /* 16:9 */
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .close-btn {
      position: absolute;
      top: -70px;
      right: -40px;
      color: white;
      font-size: 63px;
      cursor: pointer;
    }
  }
}

.video-container {
  text-align: center;
  margin-bottom: 4rem;
  max-width: 28rem;

  .video-title {
    margin-top: 1rem;
  }

  .video-thumb {
    position: relative;
  }
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, .02);
    background-size: cover;
    background-position: center;
    transition: all .5s ease;
    border: 1px solid #afafaf;
    &.light {
      border: none;
    }
  }
  .video-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/assets/img/playicon.png);
    background-size: 25%;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 250ms ease;

    &.light {
      background-image: url(/assets/img/playicon-light.png);
    }
    &:hover {
      opacity: 1;
    }
  }
}

