.use-cases-pg {

  ul {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      margin-bottom: 0.3rem;
      .icon {
        padding-right: 0.5rem;
        svg {
          height: 1.3rem;
          width: 1.3rem;
          vertical-align: middle;
          margin-right: 0.3rem;
        }
      }
      .text {
        font-size: 1.3rem;
      }
    }
  }

  .info {
    max-width: none;
  }

  .header-image {
    //background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1)), url('../img/background/1.png');
    //background-color: #4c4c4c;
    color: white;
    min-height: 21rem;

    .databases-logo {
      padding-top: 2rem;
      img {
        width: 475px;
      }
    }
  }

  section.k10-in-action {
    .row {
      margin-bottom: 2rem !important;
    }
    .info {
      text-align: left;
      background-color: #f7f7f7;
      padding: 2rem;

      .subtitle {
        font-size: 1rem;
        font-weight: 600;
      }

      .info-title {
        display: inline-block;
        padding: 0;
        margin-bottom: 1.2rem;
        margin-left: -0.7rem;
        font-size: 1.725rem;

        &.orange {
        }
        &.green {
        }
        &.smaller {
          font-size: 1.45rem;
        }
      }
      h2 {
        font-weight: 700;
      }
      p {
        font-weight: 500;
        padding: 0 25px 0 0;
        color: $body-color;
      }
    }
  }


  .section.key-features {
    background-color: #44474c;
    color: white;

    .container .info {
      .icon, .info-title, p {
        color: white;
        svg {
          font-size: 4.3rem;
        }
      }
    }
  }

  .section.use-case-topics {
    .row {
      margin-bottom: 2rem;
    }
    .info {
      $bg-color: #f6f9ff;
      padding: 2rem;
      background-color: #f7f7f7;
      border-radius: 5px;
      transition: all 300ms;

      &:hover {
        transform: translateY(-5px);
        box-shadow: $box-shadow;
      }
      .icon {
        color: $orange;
        font-size: 3rem;
      }
      p {
        font-weight: 500;
        text-decoration: none !important;
      }
      .btn {
        margin-top: 1rem;
        font-weight: bold;
      }
    }
  }

  @include media-breakpoint-down(xs) {
  }

}
