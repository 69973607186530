.kanister-page {

  .header-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)), url('../img/background/extracolor2.png');
    background-color: #181222;

    .kanister-graphic {
      padding-top: 2rem;
      svg {
        max-width: 34rem;
        padding-bottom: 0.5rem;
      }
    }


  } // .header-image

  .section.kanister-is-available {
    padding: 2rem;

    .kanister-links {
      .social-icons {
        text-align: left;
        .social-icon {
          padding-bottom: 0.3rem;
          font-size: 1.1rem;
        }
        svg {
          width: 1.2rem;
          height: 1.2rem;
          margin-right: 0.5rem;
        }
        a {
          color: $body-color;
        }
      }
    }

    pre {
    }
    code {
    }
  }

  .section.why-use-kanister {
    background-color: #1b1920;
    color: white;

    .info {
      padding-top: 20px;
      max-width: none;
      .info-title,
      p,
      .icon,
      svg {
        color: white;
      }

      .icon {
        margin-top: 2rem;
        &.app-icon {
          margin-top: 1.2rem;
          margin-right: 0;
          svg {
            width: 4.6rem;
            height: 4.6rem;
          }
        }
      }
    }

    svg {
      color: white;
      width: 50px;
      height: auto;
    }

  } // .section.why-use-kanister

  .section.three-easy-steps {
    .circle-step {
      display: inline-flex;
      flex-wrap: wrap;
    }

    .step {
      padding: 3rem;
      border-top: 1px dotted $color-logo-blue;
    }
    .circle-number {
      display: inline-block;
      border-radius: 50%;
      width: 3.4rem;
      height: 3.2rem;
      background-color: $color-logo-blue;
      color: white;
      padding-top: 0.1rem;
      padding-left: 0.1rem;
      font-size: 2rem;
      font-weight: 800;
      text-align: center;
      margin-right: 2rem;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .step-title {
      padding-top: 0.2rem;
      font-weight: bold;
      font-size: 2rem;
      color: $color-logo-blue;
    }

    p {
      font-weight: normal;
    }

    .sub-step-title {
      margin-left: 3rem;
      font-weight: bold;
    }

    .sub-step-box {
      display: flex;
      padding: 1rem;
      .icon-left {
        svg {
          width: 3rem;
          height: 3rem;
        }
        padding-right: 2rem;
      }
      .content-right {
        text-indent: -1rem;
      }
    }

    .kanister-diagram {
      margin-bottom: 3rem;
      svg {
        width: 90%;
        height: 11rem;
      }
    }

    .code-container {
      position: relative;
      .highlight-box {
        position: absolute;
        background-color: rgba(50, 253, 255, 0.35);
        height: 1.3rem;
        width: 95%;
        top: 19rem;
        margin-left: 1rem;
      }
    }

  } // .section.three-easy-steps

  @include media-breakpoint-down(xs) {

    .section-image {
      h1 {
        font-size: 2.5rem;
      }
      h4 {
        font-size: 1.2em;
      }
    }

    .section.what-is-kanister {
      pre {
        margin-left: 0;
      }
    }

    .section.three-easy-steps {
      .step {
        padding: 2rem 0.5rem;
      }
      .sub-step-title {
        margin-left: 0;
      }
      .sub-step-box .icon-left svg {
        width: 3rem;
      }
    }
  }

} // .kanister-page

.kanister-io {
  .navbar {
    background: #060609 !important;
    margin-bottom: 0 !important;
  }
  .header-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)) !important;
    padding-top: 1rem !important;
  }
  .kanister-logo {
    line-height: 2rem;
    font-size: 1.7rem;
    font-weight: 200;
    color: white;

    svg {
      height: 2rem;
      margin-bottom: -0.5rem;
      margin-right: 0.3rem;
    }
  }
}
